import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import { MenuData, MenuItem, SubMenuItem } from "../data/menu"
import {
  PseudoBox,
  Box,
  Image,
  Link as ChakraLink,
  Flex,
} from "@chakra-ui/core"
import AnimateHeight from "react-animate-height"
import Img from "gatsby-image"
import styled from "@emotion/styled"

// icons
import { Close } from "@emotion-icons/evaicons-solid/Close"
import { Link, useStaticQuery, graphql } from "gatsby"
import { ChevronDown } from "@emotion-icons/boxicons-regular/ChevronDown"

const SideNavBar = styled(PseudoBox)`
  &.sideNavBarVisible {
    pointer-events: auto;
    touch-action: none;
  }

  &.sideNavBarVisible::before {
    opacity: 1;
  }
`

const SideNavContainer = styled(Box)`
  .sideNavBarAnimatable & {
    transition: transform 0.13s cubic-bezier(0, 0, 0.3, 1);
  }

  .sideNavBarVisible.sideNavBarAnimatable & {
    transition: transform 0.33s cubic-bezier(0, 0, 0.3, 1);
  }
`

interface DrawerProps {
  open: boolean
  onClose: () => void
}

const Drawer = (props: DrawerProps): JSX.Element => {
  const { open, onClose } = props
  const [translateX, setTranslateX] = useState<number | string>("-102%")
  const [animatable, setAnimatable] = useState(false)
  const sideBarRef = useRef(null)
  const [startX, setStartX] = useState(0)
  const [currentX, setCurrentX] = useState(0)

  const [openSubMenuItems, setOpenSubMenuItems] = useState<number[]>([])

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "AatchiTamizhLogo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (sideBarRef.current) sideBarRef.current.inert = true
    return () => {
      if (sideBarRef.current) sideBarRef.current.inert = false
    }
  }, [])

  const toggleSubMenu = (index: number) => {
    if (openSubMenuItems.includes(index)) {
      setOpenSubMenuItems(
        openSubMenuItems.filter(itemIndex => itemIndex !== index)
      )
      return
    }

    setOpenSubMenuItems([...openSubMenuItems, index])
  }

  useEffect(() => {
    if (open) {
      openMenu()
    } else {
      closeMenu()
    }
  }, [open])

  const openMenu = (e?) => {
    if (sideBarRef.current) sideBarRef.current.inert = false
    if (e) e.preventDefault()
    setTranslateX(0)
    setAnimatable(true)
  }

  const closeMenu = (e?) => {
    if (sideBarRef.current) sideBarRef.current.inert = true
    if (e) e.preventDefault()
    onClose()
    setTranslateX("-102%")
    setAnimatable(true)
  }

  const handleTouchStart = e => {
    setStartX(e.touches[0].pageX)
    setCurrentX(e.touches[0].pageX)
  }

  const handleTouchMove = e => {
    setCurrentX(e.touches[0].pageX)
    const translateX = Math.min(0, currentX - startX)
    setTranslateX(translateX)
  }

  const handleTouchEnd = e => {
    const translateX = Math.min(0, currentX - startX)
    if (translateX < -50) {
      closeMenu()
    } else {
      openMenu()
    }
  }

  const blockClicks = e => {
    e.stopPropagation()
  }

  const handleTransitionEnd = e => {
    setAnimatable(false)
  }

  const renderMenuItem = (item: MenuItem, index: number) => {
    if (!item.hasSubMenu) {
      return (
        <Box key={index}>
          <Link to={item.href}>
            <PseudoBox
              as="span"
              px={3}
              py={4}
              display="inline-block"
              w="100%"
              textDecoration="none"
              color="#707070"
              _active={{ color: "#0d44a6" }}
            >
              <Flex>
                <Image w="30px" mr={2} h="20px" src={item.icon} />
                {item.title}
              </Flex>
            </PseudoBox>
          </Link>
        </Box>
      )
    }

    const isSubMenuOpen = openSubMenuItems.includes(index)

    return (
      <Box key={index} px={4} color="#707070">
        <Flex align="center" py={3} onClick={() => toggleSubMenu(index)}>
          <Image w="30px" mr={2} h="20px" src={item.icon} />
          {item.title}
          <Box transform={isSubMenuOpen ? "rotate(180deg)" : "none"}>
            <ChevronDown size="1.5rem" />
          </Box>
        </Flex>
        <AnimateHeight duration={500} height={isSubMenuOpen ? "auto" : 0}>
          {item.subMenuItems.map((item: SubMenuItem, index) => (
            <Box key={index}>
              <Link to={item.href}>
                <PseudoBox
                  as="span"
                  pl={4}
                  py={3}
                  display="inline-block"
                  w="100%"
                  textDecoration="none"
                  color="#707070"
                  _active={{ color: "#0d44a6" }}
                >
                  {item.title}
                </PseudoBox>
              </Link>
            </Box>
          ))}
        </AnimateHeight>
      </Box>
    )
  }

  const classes = classNames({
    sideNavBarVisible: open,
    sideNavBarAnimatable: animatable,
  })

  // if translateX does not have unit, append px to it
  const translateAmt = isNaN(translateX) ? translateX : `${translateX}px`

  const style = {
    transform: `translateX(${translateAmt})`,
  }

  return (
    <SideNavBar
      position="fixed"
      top={0}
      left={0}
      w="100%"
      h="100%"
      pointerEvents="none"
      zIndex={1}
      className={classes}
      onClick={closeMenu}
      _before={{
        content: `""`,
        display: "block",
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        background: "rgba(0, 0, 0, 0.5)",
        transition: "opacity 0.3s cubic-bezier(0, 0, 0.3, 1)",
      }}
    >
      <SideNavContainer
        bg="white"
        style={style}
        position="absolute"
        top={0}
        left={0}
        w="70%"
        maxW="400px"
        h="100%"
        overflowY="auto"
        onClick={blockClicks}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTransitionEnd={handleTransitionEnd}
      >
        <Box mt={5} ml={3}>
          <Link to="/">
            <Img
              style={{ width: "140px" }}
              fluid={data.logo.childImageSharp.fluid}
              alt="logo"
            />
          </Link>
        </Box>
        <ChakraLink
          position="absolute"
          top="10px"
          right="10px"
          href="#0"
          onClick={closeMenu}
        >
          <Close size="1.8rem" color="#BE0800" />
        </ChakraLink>
        <Box mt={4}>{MenuData.map(renderMenuItem)}</Box>
      </SideNavContainer>
    </SideNavBar>
  )
}

export default Drawer
