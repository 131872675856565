import React, { useState } from "react"
import { Flex, Box } from "@chakra-ui/core"
import { Menu } from "@emotion-icons/boxicons-regular/Menu"
import Drawer from "./Drawer"
import { Link, useStaticQuery, graphql } from "gatsby"
import Button from "./Button"
import AdmissionButton from "./AdmissionButton"
import Img from "gatsby-image"

export const headerHeight = "60px"

interface MobileMenuProps {
  shrinkHeight: boolean
}

const MobileMenu = (props: MobileMenuProps) => {
  const [open, setOpen] = useState(false)
  const { shrinkHeight } = props

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "AatchiTamizhLogo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const handleAnchorClick = e => {
    e.preventDefault()
    setOpen(true)
  }

  return (
    <Box
      as="nav"
      zIndex={200}
      w="100%"
      bg="rgba(255, 255, 255, 0.95)"
      display={["block", null, "none"]}
      position={shrinkHeight ? "fixed" : "relative"}
      top={0}
      boxShadow="0 1px 4px rgba(26,26,67,.1)"
    >
      <Drawer open={open} onClose={() => setOpen(false)} />
      <Flex py={3}>
        <Button px={0} bg="transparent" onClick={handleAnchorClick}>
          <Menu color="#2E3292" size="1.6rem" />
        </Button>
        <Flex flex="1" justify="space-between">
          <Link to="/">
            <Img
              style={{ width: "140px" }}
              fluid={data.logo.childImageSharp.fluid}
              alt="logo"
            />
          </Link>
          <AdmissionButton />
        </Flex>
      </Flex>
    </Box>
  )
}

export default MobileMenu
