import React from "react"
import { Box, PseudoBox } from "@chakra-ui/core"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"

const slide = keyframes`
from {
  transform: translateX(120%);
 }
to {
  transform: translateX(-100%);
 }
 
`
const AnimatedContainer = styled(PseudoBox)`
  &:hover {
    animation-play-state: paused;
  }
`

const Announcements = (): JSX.Element => {
  return (
    <Box py={1} bg="#2E3292" fontSize="md" h="30px" color="white">
      <AnimatedContainer animation={`${slide} 30s infinite linear}`}>
        <PseudoBox
          display="flex"
          _hover={{ textDecoration: "underline" }}
          whiteSpace="nowrap"
        >
          <Link to="/tnpsc2022">
            டி.என்.பி.எஸ்.சி தேர்வு போட்டிக்கு இப்போது இலவசமாக பதிவு செய்து ரூ.
            1 லட்சம் வெல்லுங்கள். &nbsp;
            <Box as="span" textDecoration="underline">
              கிளிக் செய்க &nbsp;மேலும் தகவல்களுக்கு
            </Box>
            &nbsp; +91 8939 4673 23 அழையுங்கள்.
          </Link>
        </PseudoBox>
      </AnimatedContainer>
    </Box>
  )
}

export default Announcements
