import React from "react"
import Button from "./Button"
import { Box } from "@chakra-ui/core"
import { keyframes } from "@emotion/core"

const blinkAnimation = keyframes`
  50% {
    opacity: 0.3;
  }
`

const AdmissionButton = (): JSX.Element => {
  return (
    <a href="/#register">
      <Button
        fontSize={["md", "lg"]}
        fontWeight="bold"
        mr={1}
        color="#000"
        bg="#FFD400"
      >
        <Box as="span" animation={`${blinkAnimation} 1s linear infinite`}>
          மாணவர் சேர்க்கை
        </Box>
      </Button>
    </a>
  )
}

export default AdmissionButton
