import React, { useState } from "react"
import {
  Box,
  Flex,
  Image,
  Text,
  Link as Chakralink,
  Divider,
} from "@chakra-ui/core"
import { MenuData, MenuItem } from "../data/menu"
import StyledNavLink from "./StyledNavLink"
import StyledLinkDecoration from "./StyledLinkDecoration"
import Menu from "./Menu"
import { ChevronDown } from "@emotion-icons/boxicons-regular/ChevronDown"
import TelephoneIcon from "../../images/telephone.svg"
import MailIcon from "../../images/mail.svg"
import { Link, useStaticQuery, graphql } from "gatsby"
import AdmissionButton from "./AdmissionButton"
import Img from "gatsby-image"
import Announcements from "./Home/Announcements"
import SocialIcons from "./SocialIcons"
import { Zoom } from "react-awesome-reveal"

export const SocialIcon = ({
  icon,
  alt,
  bg,
  href,
}: {
  icon: string
  alt: string
  bg: string
  href: string
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      background={bg}
      w="30px"
      h="30px"
      p={2}
    >
      <a href={href} target="_blank">
        <Image maxH="15px" src={icon} alt={alt} />
      </a>
    </Flex>
  )
}

interface StyledNavBarProps {
  shrinkHeight: boolean
  isIndexPage: boolean
}

const StyledNavBar = (props: StyledNavBarProps) => {
  const { isIndexPage, shrinkHeight } = props
  const [openSubMenuItems, setOpenSubMenuItems] = useState<number[]>([])

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "AatchiTamizhLogo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aatchiTamilukkuVarungal: file(
        relativePath: { eq: "AatchiTamilukkuVarungal.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const closeSubMenu = (index: number) => {
    if (!openSubMenuItems.includes(index)) {
      return
    }
    setOpenSubMenuItems(
      openSubMenuItems.filter(itemIndex => itemIndex !== index)
    )
  }

  const openSubMenu = (index: number) => {
    if (openSubMenuItems.includes(index)) {
      return
    }
    setOpenSubMenuItems([...openSubMenuItems, index])
  }

  const renderMenuItem = (item: MenuItem, index: number) => {
    if (!item.hasSubMenu) {
      return (
        <>
          <StyledNavLink
            key={index}
            to={item.href}
            partiallyActive={item.partiallyActive === true}
          >
            {item.title}
          </StyledNavLink>
          {MenuData.length - 1 !== index && (
            <Divider
              mt={1}
              ml={0}
              orientation="vertical"
              opacity={1}
              h="15px"
              borderColor="#9e9e9e"
              borderWidth="1px"
              alignSelf="center"
            />
          )}
        </>
      )
    }

    const isSubMenuOpen = openSubMenuItems.includes(index)

    return (
      <>
        <Flex
          key={index}
          onMouseEnter={() => openSubMenu(index)}
          onMouseLeave={() => closeSubMenu(index)}
          position="relative"
        >
          <StyledLinkDecoration>
            {item.title}
            <ChevronDown size="1.5rem" />
          </StyledLinkDecoration>
          <Box position="absolute" top="100%" minW="250px" left="0%" zIndex={1}>
            <Menu
              open={isSubMenuOpen}
              links={item.subMenuItems.map(item => ({
                to: item.href,
                text: item.title,
              }))}
            />
          </Box>
        </Flex>
        {MenuData.length - 1 !== index && (
          <Divider
            mt={1}
            ml={0}
            orientation="vertical"
            opacity={1}
            h="15px"
            borderColor="#9e9e9e"
            borderWidth="1px"
            alignSelf="center"
          />
        )}
      </>
    )
  }

  return (
    <Box
      bg="#fff"
      display={["none", null, "block"]}
      position={isIndexPage || shrinkHeight ? "fixed" : "relative"}
      top={0}
      zIndex={100}
      w="100%"
      maxW="1600px"
      left={"50%"}
      transform="translateX(-50%)"
      boxShadow={shrinkHeight ? "0 6px 10px rgba(0,0,0,.08)" : "none"}
    >
      <Flex pr={3} w="100%" align="center" justify="space-between">
        <Box p={3} flex={1}>
          <Link to="/">
            <Box mb={-1} w="280px">
              {shrinkHeight ? (
                <Zoom duration={500} triggerOnce>
                  <Img fluid={data.logo.childImageSharp.fluid} alt="logo" />
                </Zoom>
              ) : (
                <Img
                  fluid={data.aatchiTamilukkuVarungal.childImageSharp.fluid}
                  alt="logoalt"
                />
              )}
            </Box>
          </Link>
        </Box>
        <Box px={0}>
          <Flex align="center" justify="flex-end" wrap="wrap">
            <Box>
              <SocialIcons />
            </Box>
            <Divider
              mt={1}
              ml={0}
              orientation="vertical"
              opacity={1}
              h="15px"
              borderColor="#9e9e9e"
              borderWidth="1px"
            />
            <Box ml={1}>
              <Chakralink href="mailto:info@aatchithamizhiasacademy.com">
                <Image src={MailIcon} alt="mail" />
              </Chakralink>
            </Box>
            <Divider
              mt={1}
              orientation="vertical"
              opacity={1}
              h="15px"
              borderColor="#9e9e9e"
              borderWidth="1px"
            />

            <Chakralink href="tel:+91-8939-4673-23" mr={5}>
              <Flex align="center">
                <Image mr={1} src={TelephoneIcon} alt="telephone" />
                <Text fontFamily="Helvetica Neue" fontWeight="bold">
                  +91 8939 4673 23
                </Text>
              </Flex>
            </Chakralink>
            <Box pt={1}>
              <AdmissionButton />
            </Box>
          </Flex>
          <Flex
            maxW="1200px"
            color="#212121"
            fontWeight="600"
            transition="all 0.3s"
            px={1}
            flexWrap="wrap"
          >
            {MenuData.map(renderMenuItem)}
          </Flex>
        </Box>
      </Flex>
      <Announcements />
    </Box>
  )
}
export default StyledNavBar
