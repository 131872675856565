import React from "react"
import { Box, Image, Link } from "@chakra-ui/core"
import TelegramIcon from "../../images/telegram.svg"

const FixedTelegram = (): JSX.Element => {
  return (
    <Box position="fixed" bottom="5.5rem" right="1.5rem" zIndex={999}>
      <Link href="https://t.me/aatchithamizh" target="_blank">
        <Image w={["40px", "45px"]} src={TelegramIcon} alt="telegram" />
      </Link>
    </Box>
  )
}

export default FixedTelegram
