import { Box, PseudoBox } from "@chakra-ui/core"
import React from "react"

interface StyledLinkDecorationProps {
  children: React.ReactNode
}

const StyledLinkDecoration = (props: StyledLinkDecorationProps) => {
  const { children } = props
  return (
    <Box fontSize="lg" px={4} py={2}>
      <PseudoBox
        cursor="pointer"
        py={1}
        position="relative"
        transition="all 0.3s"
        _hover={{ color: "#0D44A6" }}
        _after={{
          position: "absolute",
          content: '""',
          height: "3px",
          left: "0",
          bottom: "0",
          width: "100%",
          bg: "#fff",
          transition: "transform 250ms ease-in",
          transformOrigin: "right",
          transform: "scaleX(0)",
        }}
      >
        {children}
      </PseudoBox>
    </Box>
  )
}

export default StyledLinkDecoration
