import React from "react"
import {
  Box,
  Flex,
  Text,
  Image,
  Stack,
  List,
  Input,
  Heading,
  SimpleGrid,
  Link,
} from "@chakra-ui/core"
import Logo from "../../images/AatchiTamizhLogo.png"
import PhoneIcon from "../../images/telephone.svg"
import MailIcon from "../../images/mail.svg"
import WhatsappIcon from "../../images/whatsappFooter.svg"
import FacebookIcon from "../../images/facebookFooter.svg"
import InstagramIcon from "../../images/instagramFooter.svg"
import TelegramIcon from "../../images/telegramFooter.svg"
import YoutubeIcon from "../../images/youtubeFooter.svg"
import TwitterIcon from "../../images/twitterFooter.svg"
import Button from "./Button"
import { SocialIcon } from "./StyledNavBar"
import { Copyright } from "@emotion-icons/boxicons-regular/Copyright"

const SocialShareSection = () => {
  return (
    <Flex align="center" justify="space-between">
      <Text color="#fff">Follow us on</Text>
      <Stack isInline ml={6} spacing={2}>
        <Link
          href={"https://api.whatsapp.com/send/?phone=919843511188"}
          target="_blank"
        >
          <Image maxH="17px" src={WhatsappIcon} alt="whatsapp" />
        </Link>
        <Link href={"https://t.me/aatchithamizh"} target="_blank">
          <Image maxH="17px" src={TelegramIcon} alt="telegram" />
        </Link>
        <Link
          href={"https://www.facebook.com/aatchithamizhias"}
          target="_blank"
        >
          <Image maxH="17px" src={FacebookIcon} alt="facebook" />
        </Link>
        <Link href={"https://twitter.com/aatchitamil"} target="_blank">
          <Image maxH="17px" src={TwitterIcon} alt="twitter" />
        </Link>
        <Link
          href={" https://www.facebook.com/aatchithamizhias"}
          target="_blank"
        >
          <Image maxH="17px" src={InstagramIcon} alt="instagram" />
        </Link>
        <Link
          href="https://youtube.com/channel/UC7UlO-D5U17bpoC2rasoFwQ"
          target="_blank"
        >
          <Image maxH="17px" src={YoutubeIcon} alt="youtube" />
        </Link>
      </Stack>
    </Flex>
  )
}

const Footer = () => {
  return (
    <Box>
      <Box bg="#f8f8f8">
        <Box
          px={[0, null, 5]}
          pt={10}
          pb={[0, null, 16]}
          maxW="1400px"
          mx="auto"
          mb={4}
        >
          <Image mb={2} pl={6} src={Logo} alt="logo" height="75px" />
          <Flex justify="space-between" wrap="wrap">
            <SimpleGrid
              columns={[1, 2, 4]}
              spacing={[5, null, 20]}
              py={2}
              px={6}
            >
              <Box maxW="350px">
                <Heading mb={2} fontSize="xl">
                  தலைமை அலுவலகம்
                </Heading>
                <Text color="#8B8B8B" fontWeight="500" fontSize="sm" mb={2}>
                  ஆட்சித் தமிழ் ஐ.ஏ.எஸ் அகாடெமி, 142,ஜி.எஸ்.டி ரோடு, வெற்றி
                  தியேட்டர் எதிரில், குரோம்பேட்டை, சென்னை – 600 044. (சரவணா
                  ஸ்டோர்ஸ் அருகில்)
                </Text>
                <Heading size="sm" mb={1}>
                  கிளை அலுவலகம் :{" "}
                </Heading>
                <Text color="#8B8B8B" fontWeight="500" fontSize="sm" mb={4}>
                  ஆட்சித் தமிழ் ஐ.ஏ.எஸ் அகாடெமி , நன்மயம் (3வது தளம்), 19,
                  அழகேசன் ரோடு, வேதாச்சலம் நகர், (செயின்ட்ஜோசப் மெட்ரிகுலேஷன்
                  பள்ளி எதிரில்), செங்கல்பட்டு – 603001
                </Text>
              </Box>
              <Box>
                <Heading mb={2} fontSize="3xl">
                  முகப்பு
                </Heading>
                <List fontSize="sm" styleType="none" color="#8b8b8b">
                  <li>எங்களை பற்றி</li>
                  <li>படிப்புகள்</li>
                  <li>டி.என்.பி.எஸ்.சி இலவச போட்டித்தேர்வு</li>
                  <li>எங்களை பற்றி</li>
                  <li>டி.என்.பி.எஸ்.சி இலவச போட்டித்தேர்வு</li>
                  <li>படிப்புகள்</li>
                </List>
              </Box>
              <Box>
                <Heading mb={2} fontSize="3xl">
                  முகப்பு
                </Heading>
                <List fontSize="sm" styleType="none" color="#8b8b8b">
                  <li>எங்களை பற்றி</li>
                  <li>படிப்புகள்</li>
                  <li>டி.என்.பி.எஸ்.சி இலவச போட்டித்தேர்வு</li>
                  <li>எங்களை பற்றி</li>
                  <li>டி.என்.பி.எஸ்.சி இலவச போட்டித்தேர்வு</li>
                  <li>படிப்புகள்</li>
                </List>
              </Box>
              <Box>
                <Heading mb={2} fontSize="3xl">
                  முகப்பு
                </Heading>
                <List fontSize="sm" styleType="none" color="#8b8b8b">
                  <li>எங்களை பற்றி</li>
                  <li>படிப்புகள்</li>
                  <li>டி.என்.பி.எஸ்.சி இலவச போட்டித்தேர்வு</li>
                  <li>எங்களை பற்றி</li>
                  <li>டி.என்.பி.எஸ்.சி இலவச போட்டித்தேர்வு</li>
                  <li>படிப்புகள்</li>
                </List>
              </Box>
            </SimpleGrid>
            <Flex
              align={["flex-start", "flex-end"]}
              justify="space-between"
              w="100%"
              direction={["column", "row"]}
              px={6}
              mt={2}
            >
              <Box>
                <Flex align="flex-end" fontFamily="Helvetica" fontWeight="bold" color="#8B8B8B">
                  <Image maxH="1rem" src={PhoneIcon} alt="phone" />
                  <Link href="tel:+91-8939-4673-23">
                    <Text
                     lineHeight="0.8"
                     pl={2}
                    >
                      +91 8939 4673 23,
                    </Text>
                  </Link>
                  &nbsp;
                  <Link href="tel:+91-9843-5111-88">
                    <Text lineHeight="0.8">
                      +91 9843 5111 88,
                    </Text>
                  </Link>
                  &nbsp;
                  <Link href="tel:+91-9943-9555-11">
                    <Text lineHeight="0.8">
                      +91 9943 9555 11,
                    </Text>
                  </Link>
                  &nbsp;
                  <Link href="tel:+91-9962-6000-43">
                    <Text lineHeight="0.8">
                      +91 9962 6000 43
                    </Text>
                  </Link>
                </Flex>
                <Flex align="flex-end" mt={3} mb={4}>
                  <Image maxH="1rem" src={MailIcon} alt="phone" />
                  <Link href="mailto:info@aatchithamizhiasacademy.com">
                    <Text
                      color="#8B8B8B"
                      fontSize="sm"
                      lineHeight="0.8"
                      fontWeight="bold"
                      pl={2}
                    >
                      info@aatchithamizhiasacademy.com
                    </Text>
                  </Link>
                </Flex>
                <Flex mt={5}>
                  <Input fontSize="sm" placeholder="மேலும் தகவலுக்கு" />
                  <Button
                    fontSize="sm"
                    borderRadius={0}
                    px={8}
                    bg="#E72930"
                    color="#fff"
                  >
                    Contact us
                  </Button>
                </Flex>
              </Box>
              <Box mt={5}>
                <Flex align="flex-end">
                  <Text fontWeight="bold" fontSize="lg" mx={2}>
                    Follow us on {"  "}
                  </Text>
                  <SocialIcon
                    href="https://youtube.com/channel/UC7UlO-D5U17bpoC2rasoFwQ"
                    icon={YoutubeIcon}
                    alt="youtube"
                    bg="#EB1D27"
                  />
                  <SocialIcon
                    href="https://twitter.com/aatchitamil"
                    icon={TwitterIcon}
                    alt="twitter"
                    bg="#0061C1"
                  />
                  <SocialIcon
                    href="https://www.facebook.com/aatchithamizhias"
                    icon={FacebookIcon}
                    alt="facebook"
                    bg="#1B7FE2"
                  />
                  <SocialIcon
                    href="https://www.facebook.com/aatchithamizhias"
                    icon={InstagramIcon}
                    alt="instagram"
                    bg="linear-gradient(225deg, #405DE6 0%, #5851DB 23%, #833AB4 44%, #C13584 63%, #E1306C 80%, #FD1D1D 100%)"
                  />
                  <SocialIcon
                    href="https://t.me/aatchithamizh"
                    icon={TelegramIcon}
                    alt="telegram"
                    bg="#0265B9"
                  />
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box fontSize="lg" py={1} bg="#3C3C3C" color="#fff">
        <Flex justify="center" p={3} px={6} alignItems="center">
          <Copyright size="1.5rem" />
          <Text mt={"3px"}> &nbsp; Aatchitamizh IAS Academy</Text>
        </Flex>
      </Box>
    </Box>
  )
}
export default Footer
