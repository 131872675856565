import HomeIcon from "../../images/home.svg"
import PictureIcon from "../../images/picture.svg"
import InfoIcon from "../../images/information.svg"
import CapIcon from "../../images/cap.svg"
import OutlineIcon from "../../images/outline.svg"
import SocialMediaIcon from "../../images/social-media.svg"

export interface SubMenuItem {
  title: string
  href: string
  partiallyActive?: boolean
}

interface MenuItemWithSubMenu {
  href?: string
  title: string
  hasSubMenu: true
  subMenuItems: SubMenuItem[]
  partiallyActive?: boolean
  icon: string
}

interface MenuItemWithoutSubMenu {
  title: string
  href: string
  hasSubMenu: false
  partiallyActive?: boolean
  icon: string
}

export type MenuItem = MenuItemWithSubMenu | MenuItemWithoutSubMenu

export const MenuData: MenuItem[] = [
  {
    title: "இந்திய ஆட்சிப் பணித் தேர்வு",
    href: "/upsc",
    hasSubMenu: false,
    icon: CapIcon,
  },
  {
    title: "தமிழ்நாடு அரசுப் பணித் தேர்வு",
    href: "/tnpsc",
    hasSubMenu: false,
    icon: PictureIcon,
  },
  { title: "இதர தேர்வுகள்", href: "/otherexams", hasSubMenu: false, icon: InfoIcon },
  { title: "ஆட்சித்தமிழ்", href: "/about", hasSubMenu: false, icon: InfoIcon },
  { title: "மெய்நிகர் தேர்வு ", href: "/tnpscMockTest", hasSubMenu: false, icon: InfoIcon },
  {
    title: "மேலும்",
    href: "/",
    hasSubMenu: true,
    icon: OutlineIcon,
    subMenuItems: [
      {
        href: "/blog",
        title: "நடப்பு சேதி",
      },
      {
        href: "/gallery",
        title: "வலைப்பக்கம்",
      },
      {
        href: "/blog",
        title: "பதிவுகள்",
      },
      {
        href: "/gallery",
        title: "காட்சி நிரல்",
      },
     {
          href: "/blog",
          title: "கருத்தரங்குகள்",
        },
        {
          href:"/blog",
          title:"நேர்காணல்"
        }
    ],
  },
]
