import React from "react"
import { Box, Image, Link } from "@chakra-ui/core"
import WhatsappIcon from "../../images/fixedWhatsapp.svg"

const FixedWhatsapp = (): JSX.Element => {
  return (
    <Box position="fixed" bottom="1.3rem" right="1.3rem" zIndex={999}>
      <Link
        href="https://api.whatsapp.com/send/?phone=919843511188"
        target="_blank"
      >
        <Image w={["45px", "50px"]} src={WhatsappIcon} alt="whatsapp" />
      </Link>
    </Box>
  )
}

export default FixedWhatsapp
