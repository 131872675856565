import React from "react"
import { Stack, Collapse, PseudoBox } from "@chakra-ui/core"
import Link from "gatsby-link"

interface MenuLinkProps {
  children: React.ReactNode
  to: string
}

const MenuLink = (props: MenuLinkProps) => {
  const { children, to } = props
  return (
    <Link to={to} activeClassName="active">
      <PseudoBox
        textAlign="left"
        fontWeight="bold"
        p={2}
        _hover={{
          bg: "#0D44A6",
          color: "#fff",
        }}
      >
        {children}
      </PseudoBox>
    </Link>
  )
}

interface LinkInterface {
  text: string
  to: string
}

interface MenuProps {
  open: boolean
  links: LinkInterface[]
}

const Menu = (props: MenuProps) => {
  return (
    <Collapse duration={500} isOpen={props.open}>
      <Stack
        color="#0D44A6"
        bg="#fff"
        boxShadow="0px 3px 6px #00000029"
        w="200px"
      >
        {props.links.map((link, index) => (
          <MenuLink key={index} to={link.to}>
            {link.text}
          </MenuLink>
        ))}
      </Stack>
    </Collapse>
  )
}

export default Menu
