import React, { useState } from "react"
import { Box, Flex, Image } from "@chakra-ui/core"
import FacebookIcon from "../../images/facebook.svg"
import WhatsappIcon from "../../images/whatsapp.svg"
import InstagramIcon from "../../images/instagram.svg"
import TelegramIcon from "../../images/telegram.svg"
import YoutubeIcon from "../../images/youtube.svg"
import TwitterIcon from "../../images/twitter.svg"

interface IconButtonProps {
  src: string
  alt: string
  isHovered: boolean
  styles?: { opacity: number; transform: string; zIndex?: number }
  href: string
}

const IconButton = (props: IconButtonProps): JSX.Element => {
  const { src, alt, styles, isHovered, href } = props

  return (
    <Box
      width="23px"
      mr={2}
      transition="all 0.3s"
      transform={!isHovered ? styles.transform : ""}
      opacity={!isHovered ? styles.opacity : 1}
      zIndex={!isHovered ? styles.zIndex : 0}
    >
      <a href={href} target="_blank">
        <Image maxWidth="100%" src={src} alt={alt} />
      </a>
    </Box>
  )
}

const SocialIcons = (): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Flex
      mt={1}
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconButton
        styles={{
          transform: "translate(600%)",
          zIndex: 1,
          opacity: 1,
        }}
        isHovered={isHovered}
        src={FacebookIcon}
        alt="facebook"
        href="https://www.facebook.com/aatchithamizhias"
      />
      <IconButton
        styles={{
          transform: "translate(420%) scale(0.8)",
          opacity: 0.6,
        }}
        isHovered={isHovered}
        src={WhatsappIcon}
        alt="whatsapp"
        href="https://api.whatsapp.com/send/?phone=919843511188"
      />
      <IconButton
        styles={{
          transform: "translate(320%) scale(1)",
          opacity: 0.4,
        }}
        isHovered={isHovered}
        src={YoutubeIcon}
        alt="youtube"
        href="https://youtube.com/channel/UC7UlO-D5U17bpoC2rasoFwQ"
      />
      <IconButton
        styles={{
          transform: "translate(200%) scale(0.8)",
          opacity: 0.4,
        }}
        isHovered={isHovered}
        src={InstagramIcon}
        alt="instagram"
        href="https://www.facebook.com/aatchithamizhias"
      />
      <IconButton
        styles={{
          transform: "translate(120%) scale(0.8)",
          opacity: 0.4,
        }}
        isHovered={isHovered}
        src={TwitterIcon}
        alt="twitter"
        href="https://twitter.com/aatchitamil"
      />
      <IconButton
        styles={{
          transform: "translate(-50%) scale(0.9)",
          opacity: 0.6,
        }}
        isHovered={isHovered}
        src={TelegramIcon}
        alt="telegram"
        href="https://t.me/aatchithamizh"
      />
    </Flex>
  )
}

export default SocialIcons
