import React from "react"
import { Button as ChakraButton } from "@chakra-ui/core"
import styled from "@emotion/styled"

const StyledButton = styled(ChakraButton)`
  position: relative;
  overflow: hidden;
  transform: perspective(800px) translate3d(0, 0, 0);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: perspective(800px) translate3d(0, 0, 43px);
    background-color: ${props => props.bg ?? "#E2E8F0"};
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: "50%";
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
  &:hover:after {
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
`

const Button = props => {
  return <StyledButton {...props} />
}

export default Button
