import React from "react"
import Link from "gatsby-link"
import { PseudoBox, Flex } from "@chakra-ui/core"

interface StyledNavLinkProps {
  children: React.ReactNode
  to: string
  partiallyActive?: boolean
}

const StyledNavLink = (props: StyledNavLinkProps) => {
  const { children, to } = props
  return (
    <Flex fontSize="lg" align="center" px={2}>
      <Link
        to={to}
        activeStyle={{
          color: "#0D44A6",
          fontWeight: "bold",
          borderBottom: "1px solid #0D44A6",
        }}
        partiallyActive={props.partiallyActive}
      >
        <PseudoBox
          py={1}
          position="relative"
          transition="all 0.3s"
          _hover={{
            color: "#0D44A6",
            borderBottom: "1px solid #0D44A6",
          }}
        >
          {children}
        </PseudoBox>
      </Link>
    </Flex>
  )
}

export default StyledNavLink
